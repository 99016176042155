import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _42987091 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _04595ff6 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _1badd16e = () => interopDefault(import('../pages/afrekenen.vue' /* webpackChunkName: "pages/afrekenen" */))
const _ba3ce496 = () => interopDefault(import('../pages/betaling/index.vue' /* webpackChunkName: "pages/betaling/index" */))
const _6b093788 = () => interopDefault(import('../pages/brochure-aanvragen.vue' /* webpackChunkName: "pages/brochure-aanvragen" */))
const _37c68c87 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _139bdc3d = () => interopDefault(import('../pages/inloggen.vue' /* webpackChunkName: "pages/inloggen" */))
const _7b8889cf = () => interopDefault(import('../pages/kennisbank/index.vue' /* webpackChunkName: "pages/kennisbank/index" */))
const _67d15313 = () => interopDefault(import('../pages/nieuws.vue' /* webpackChunkName: "pages/nieuws" */))
const _2d29ff56 = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _7902eb24 = () => interopDefault(import('../pages/nieuws/_category/index.vue' /* webpackChunkName: "pages/nieuws/_category/index" */))
const _baedac72 = () => interopDefault(import('../pages/nieuws/_category/_item/index.vue' /* webpackChunkName: "pages/nieuws/_category/_item/index" */))
const _ced552c0 = () => interopDefault(import('../pages/over.vue' /* webpackChunkName: "pages/over" */))
const _e290784a = () => interopDefault(import('../pages/over/_slug.vue' /* webpackChunkName: "pages/over/_slug" */))
const _25864a47 = () => interopDefault(import('../pages/over-ons.vue' /* webpackChunkName: "pages/over-ons" */))
const _e34a4d82 = () => interopDefault(import('../pages/producten/index.vue' /* webpackChunkName: "pages/producten/index" */))
const _7a81b1b6 = () => interopDefault(import('../pages/registreren.vue' /* webpackChunkName: "pages/registreren" */))
const _3897fec3 = () => interopDefault(import('../pages/school/index.vue' /* webpackChunkName: "pages/school/index" */))
const _647713c2 = () => interopDefault(import('../pages/shop.vue' /* webpackChunkName: "pages/shop" */))
const _2fe240c5 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _16476dd3 = () => interopDefault(import('../pages/shop/_category/index.vue' /* webpackChunkName: "pages/shop/_category/index" */))
const _13805e3f = () => interopDefault(import('../pages/shop/_category/_product.vue' /* webpackChunkName: "pages/shop/_category/_product" */))
const _c03a6280 = () => interopDefault(import('../pages/speciaal-voor-scholen.vue' /* webpackChunkName: "pages/speciaal-voor-scholen" */))
const _ecfa2d1a = () => interopDefault(import('../pages/sso.vue' /* webpackChunkName: "pages/sso" */))
const _58c6d3e8 = () => interopDefault(import('../pages/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/index" */))
const _dd4c0018 = () => interopDefault(import('../pages/winkelwagen.vue' /* webpackChunkName: "pages/winkelwagen" */))
const _0b9f2140 = () => interopDefault(import('../pages/zoekresultaten.vue' /* webpackChunkName: "pages/zoekresultaten" */))
const _1c94784d = () => interopDefault(import('../pages/admin/abonnementen/index.vue' /* webpackChunkName: "pages/admin/abonnementen/index" */))
const _33be6901 = () => interopDefault(import('../pages/admin/facturen/index.vue' /* webpackChunkName: "pages/admin/facturen/index" */))
const _30ef3fd5 = () => interopDefault(import('../pages/admin/offertes/index.vue' /* webpackChunkName: "pages/admin/offertes/index" */))
const _479f5441 = () => interopDefault(import('../pages/admin/pakketten.vue' /* webpackChunkName: "pages/admin/pakketten" */))
const _d7da43cc = () => interopDefault(import('../pages/admin/relaties/index.vue' /* webpackChunkName: "pages/admin/relaties/index" */))
const _e2d8c13a = () => interopDefault(import('../pages/betaling/afgerond.vue' /* webpackChunkName: "pages/betaling/afgerond" */))
const _22498de5 = () => interopDefault(import('../pages/demo/rekenblobs.vue' /* webpackChunkName: "pages/demo/rekenblobs" */))
const _0fccd4be = () => interopDefault(import('../pages/demo/taalblobs.vue' /* webpackChunkName: "pages/demo/taalblobs" */))
const _7759ec46 = () => interopDefault(import('../pages/info/algemene-voorwaarden.vue' /* webpackChunkName: "pages/info/algemene-voorwaarden" */))
const _c7da9ed6 = () => interopDefault(import('../pages/info/privacy-statement.vue' /* webpackChunkName: "pages/info/privacy-statement" */))
const _7aa3ea7b = () => interopDefault(import('../pages/school/leerling.vue' /* webpackChunkName: "pages/school/leerling" */))
const _0339e853 = () => interopDefault(import('../pages/admin/facturen/aanmaken.vue' /* webpackChunkName: "pages/admin/facturen/aanmaken" */))
const _19b1b0ff = () => interopDefault(import('../pages/admin/offertes/aanmaken.vue' /* webpackChunkName: "pages/admin/offertes/aanmaken" */))
const _07a0b09a = () => interopDefault(import('../pages/admin/relaties/aanmaken.vue' /* webpackChunkName: "pages/admin/relaties/aanmaken" */))
const _d0e3ac16 = () => interopDefault(import('../pages/admin/abonnementen/_id.vue' /* webpackChunkName: "pages/admin/abonnementen/_id" */))
const _4d85337d = () => interopDefault(import('../pages/admin/offertes/_id.vue' /* webpackChunkName: "pages/admin/offertes/_id" */))
const _f3c16440 = () => interopDefault(import('../pages/admin/organisaties/_id.vue' /* webpackChunkName: "pages/admin/organisaties/_id" */))
const _99039cac = () => interopDefault(import('../pages/admin/particulieren/_id.vue' /* webpackChunkName: "pages/admin/particulieren/_id" */))
const _aadabba6 = () => interopDefault(import('../pages/admin/scholen/_id.vue' /* webpackChunkName: "pages/admin/scholen/_id" */))
const _6d526df3 = () => interopDefault(import('../pages/admin/studenten/_id.vue' /* webpackChunkName: "pages/admin/studenten/_id" */))
const _7c2a1d20 = () => interopDefault(import('../pages/wachtwoord-vergeten/reset/_token.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/reset/_token" */))
const _360feb3f = () => interopDefault(import('../pages/contact/_slug.vue' /* webpackChunkName: "pages/contact/_slug" */))
const _79d1e887 = () => interopDefault(import('../pages/kennisbank/_slug.vue' /* webpackChunkName: "pages/kennisbank/_slug" */))
const _33b028ea = () => interopDefault(import('../pages/kennisbank/_slug/_item.vue' /* webpackChunkName: "pages/kennisbank/_slug/_item" */))
const _e6b79012 = () => interopDefault(import('../pages/producten/_slug.vue' /* webpackChunkName: "pages/producten/_slug" */))
const _2b2afaa4 = () => interopDefault(import('../pages/verleng/_id.vue' /* webpackChunkName: "pages/verleng/_id" */))
const _eeb3f524 = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _7a4e57b6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _090e490e = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _42987091,
    name: "account"
  }, {
    path: "/admin",
    component: _04595ff6,
    name: "admin"
  }, {
    path: "/afrekenen",
    component: _1badd16e,
    name: "afrekenen"
  }, {
    path: "/betaling",
    component: _ba3ce496,
    name: "betaling"
  }, {
    path: "/brochure-aanvragen",
    component: _6b093788,
    name: "brochure-aanvragen"
  }, {
    path: "/contact",
    component: _37c68c87,
    name: "contact"
  }, {
    path: "/inloggen",
    component: _139bdc3d,
    name: "inloggen"
  }, {
    path: "/kennisbank",
    component: _7b8889cf,
    name: "kennisbank"
  }, {
    path: "/nieuws",
    component: _67d15313,
    children: [{
      path: "",
      component: _2d29ff56,
      name: "nieuws"
    }, {
      path: ":category",
      component: _7902eb24,
      name: "nieuws-category"
    }, {
      path: ":category/:item",
      component: _baedac72,
      name: "nieuws-category-item"
    }]
  }, {
    path: "/over",
    component: _ced552c0,
    name: "over",
    children: [{
      path: ":slug?",
      component: _e290784a,
      name: "over-slug"
    }]
  }, {
    path: "/over-ons",
    component: _25864a47,
    name: "over-ons"
  }, {
    path: "/producten",
    component: _e34a4d82,
    name: "producten"
  }, {
    path: "/registreren",
    component: _7a81b1b6,
    name: "registreren"
  }, {
    path: "/school",
    component: _3897fec3,
    name: "school"
  }, {
    path: "/shop",
    component: _647713c2,
    children: [{
      path: "",
      component: _2fe240c5,
      name: "shop"
    }, {
      path: ":category",
      component: _16476dd3,
      name: "shop-category"
    }, {
      path: ":category/:product",
      component: _13805e3f,
      name: "shop-category-product"
    }]
  }, {
    path: "/speciaal-voor-scholen",
    component: _c03a6280,
    name: "speciaal-voor-scholen"
  }, {
    path: "/sso",
    component: _ecfa2d1a,
    name: "sso"
  }, {
    path: "/wachtwoord-vergeten",
    component: _58c6d3e8,
    name: "wachtwoord-vergeten"
  }, {
    path: "/winkelwagen",
    component: _dd4c0018,
    name: "winkelwagen"
  }, {
    path: "/zoekresultaten",
    component: _0b9f2140,
    name: "zoekresultaten"
  }, {
    path: "/admin/abonnementen",
    component: _1c94784d,
    name: "admin-abonnementen"
  }, {
    path: "/admin/facturen",
    component: _33be6901,
    name: "admin-facturen"
  }, {
    path: "/admin/offertes",
    component: _30ef3fd5,
    name: "admin-offertes"
  }, {
    path: "/admin/pakketten",
    component: _479f5441,
    name: "admin-pakketten"
  }, {
    path: "/admin/relaties",
    component: _d7da43cc,
    name: "admin-relaties"
  }, {
    path: "/betaling/afgerond",
    component: _e2d8c13a,
    name: "betaling-afgerond"
  }, {
    path: "/demo/rekenblobs",
    component: _22498de5,
    name: "demo-rekenblobs"
  }, {
    path: "/demo/taalblobs",
    component: _0fccd4be,
    name: "demo-taalblobs"
  }, {
    path: "/info/algemene-voorwaarden",
    component: _7759ec46,
    name: "info-algemene-voorwaarden"
  }, {
    path: "/info/privacy-statement",
    component: _c7da9ed6,
    name: "info-privacy-statement"
  }, {
    path: "/school/leerling",
    component: _7aa3ea7b,
    name: "school-leerling"
  }, {
    path: "/admin/facturen/aanmaken",
    component: _0339e853,
    name: "admin-facturen-aanmaken"
  }, {
    path: "/admin/offertes/aanmaken",
    component: _19b1b0ff,
    name: "admin-offertes-aanmaken"
  }, {
    path: "/admin/relaties/aanmaken",
    component: _07a0b09a,
    name: "admin-relaties-aanmaken"
  }, {
    path: "/admin/abonnementen/:id",
    component: _d0e3ac16,
    name: "admin-abonnementen-id"
  }, {
    path: "/admin/offertes/:id",
    component: _4d85337d,
    name: "admin-offertes-id"
  }, {
    path: "/admin/organisaties/:id?",
    component: _f3c16440,
    name: "admin-organisaties-id"
  }, {
    path: "/admin/particulieren/:id?",
    component: _99039cac,
    name: "admin-particulieren-id"
  }, {
    path: "/admin/scholen/:id?",
    component: _aadabba6,
    name: "admin-scholen-id"
  }, {
    path: "/admin/studenten/:id?",
    component: _6d526df3,
    name: "admin-studenten-id"
  }, {
    path: "/wachtwoord-vergeten/reset/:token",
    component: _7c2a1d20,
    name: "wachtwoord-vergeten-reset-token"
  }, {
    path: "/contact/:slug",
    component: _360feb3f,
    name: "contact-slug"
  }, {
    path: "/kennisbank/:slug",
    component: _79d1e887,
    name: "kennisbank-slug",
    children: [{
      path: ":item?",
      component: _33b028ea,
      name: "kennisbank-slug-item"
    }]
  }, {
    path: "/producten/:slug",
    component: _e6b79012,
    name: "producten-slug"
  }, {
    path: "/verleng/:id?",
    component: _2b2afaa4,
    name: "verleng-id"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _eeb3f524,
    name: "wachtwoord-instellen-token"
  }, {
    path: "/",
    component: _7a4e57b6,
    name: "index"
  }, {
    path: "/*",
    component: _090e490e,
    name: "*"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decodeURIComponent(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
